.MarkdownEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-size: 14px;
  padding: 15px;
  width: 100%;
}

.MarkdownEditor-editor {
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.MarkdownEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.MarkdownEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.MarkdownEditor-editor .MarkdownEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.MarkdownEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.Markdown > blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.Markdown > pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}
